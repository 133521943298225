import React from "react";

const Contactus = () => {
  return (
    <div>
      <section class="breadcumb-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcumb-title" style={{ textAlign: "center", marginTop:'80px' }}>
                <h2 style={{ fontWeight: "600", fontSize: "36px" }}>Contact</h2>
              </div>
              <div class="breadcumb-inner" style={{ textAlign: "center" }}>
                <ul>
                  <li>
                    <a href="/" style={{ fontWeight: "600" }}>
                      Home
                    </a>
                  </li>
                  <li>
                    <i class="fa fa-angle-right"></i>
                  </li>
                  <li style={{ fontWeight: "600" }}>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Start Comfy Contact Section  --> */}
      <div class="">
        <div class="container mt-5 mb-5">
          <div class="row">
            {/* <img class="wow fadeInLeft" data-wow-delay="0.3s" src="assets/images/resource/dtls.jpg"  alt=""  style={{height:'100vh', width:'90%'}}/> */}
            {/* <img class="wow fadeInLeft" data-wow-delay="0.3s" src="assets/images/franchise/fr3.jpeg"  alt=""  style={{height:'95vh', width:'90%',borderRadius:'32px'}}/> */}
            <div class="col-lg-6 col-md-6 wow fadeInLeft" data-wow-delay="0.3s">
              <div
                class="single-feature-box"
                // style={{ border: "2px solid lightblue" }}
              >
                <div>
                  {/* <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  /> */}
                  <img
                    // src="assets/images/resource/blogs2.jpg"
                    src="assets/images/about/shop.jpg"
                    alt=""
                    style={{ height: "37vh", width: "100%" }}
                  />
                </div>
                <div class="feature-title">
                  <h3> {""} Whitefield</h3>
                </div>
                <div className="feature-details cursor-scale">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px", // Space between different sections
                    }}
                  >
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "34px", // Adjust size as needed
                        height: "34px", // Adjust size as needed
                        backgroundColor: "var(--primary-color)",
                        borderRadius: "50%",
                        color: "white",
                        marginRight: "18px", // Space between icon and text
                      }}
                    >
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                    <h6
                      style={{
                        fontWeight: 600,
                        fontSize: "18px",
                        margin: 0, // Remove default margin
                      }}
                    >
                      Location:
                    </h6>
                  </div>
                  <p style={{ marginLeft: "52px" }}>
                    Ground floor, Leelavati Achar Complex, Immadihalli Road,
                    Whitefield, Bangalore-560066.
                  </p>
                </div>

                <div className="feature-details cursor-scale">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px", // Space between different sections
                    }}
                  >
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "34px", // Adjust size as needed
                        height: "34px", // Adjust size as needed
                        backgroundColor: "var(--primary-color)",
                        borderRadius: "50%",
                        color: "white",
                        marginRight: "18px", // Space between icon and text
                      }}
                    >
                      <i className="fas fa-phone-alt"></i>
                    </span>
                    <h6
                      style={{
                        fontWeight: 600,
                        fontSize: "18px",
                        margin: 0, // Remove default margin
                      }}
                    >
                      Phone:
                    </h6>
                  </div>
                  <div style={{ marginLeft: "52px" }}>
                    <p>+91 00000-00000</p>
                    <p>+91 00000-00000</p>
                    <p>+91 00000-00000</p>
                  </div>
                </div>

                <div className="feature-details cursor-scale">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px", // Space between different sections
                    }}
                  >
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "34px", // Adjust size as needed
                        height: "34px", // Adjust size as needed
                        backgroundColor: "var(--primary-color)",
                        borderRadius: "50%",
                        color: "white",
                        marginRight: "18px", // Space between icon and text
                      }}
                    >
                      <i className="fas fa-envelope"></i>
                    </span>
                    <h6
                      style={{
                        fontWeight: 600,
                        fontSize: "18px",
                        margin: 0, // Remove default margin
                      }}
                    >
                      Email:
                    </h6>
                  </div>
                  <p style={{ marginLeft: "52px" }}>info@tiranga.com</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact_from_box">
                <div class="comfy-section-title text-left">
                  <h5>Contact Us</h5>
                  <h4 style={{ fontWeight: "600" }}>
                    We Would Like To Hear From You!
                  </h4>
                  <p style={{ color: "black" }}>
                    Delight us with your tea stories..! Send us your cherished
                    garam-garam experience..!
                  </p>
                </div>
                <form
                  class="dreamit-form"
                  action="https://formspree.io/f/myyleorq"
                  method="POST"
                >
                  <div class="form-box">
                    <input
                      id="name2"
                      type="text"
                      name="name"
                      placeholder="Name:"
                      autocomplete="on"
                      required
                    />
                  </div>
                  <div class="form-box">
                    <input
                      id=""
                      type="number"
                      name="name"
                      placeholder="Mobile Number:"
                      autocomplete="on"
                    />
                  </div>
                  <div class="form-box">
                    <input
                      id="emal2"
                      type="email"
                      name="name"
                      placeholder="Email:"
                      autocomplete="on"
                    />
                  </div>
                  <div class="form-box upp">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      placeholder="Your Message"
                      autocomplete="off"
                    ></textarea>
                  </div>
                  <div class="contact-form">
                    <button type="submit"> Submit</button>
                  </div>
                </form>
                <div class="status"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="feature-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 wow fadeInDown">
              <div class="comfy-section-title">
                <div class="section-icon">
                  {/* <img src="assets/images/resource/section-icon.png" alt="" /> */}
                  <div class="section-icon">
                    <img
                      src="assets/images/feature/feature.png"
                      alt=""
                      style={{ width: "5%" }}
                    />
                  </div>
                </div>
                <h5>Our Branches</h5>
                <h2 style={{ fontWeight: "600", fontSize: "32px" }}>
                  We available at all locations in Bangalore
                </h2>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0.3s">
              <div
                class="single-feature-box"
                style={{ border: "2px solid lightblue" }}
              >
                <div>
                  <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  />
                </div>
                <div class="feature-title">
                  <h3>
                    {" "}
                    <i class="fas fa-map-marker-alt"></i>
                    {""} Indiranagar
                  </h3>
                </div>
                <div class="feature-details cursor-scale">
                  <h6
                    style={{
                      float: "left",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Address:
                  </h6>
                  <p>
                    D.No : 86-4-8/1, Tilak Road, Beside Lakshmi Dept Store,
                    Rajahmundry, East Godavari, Andhra Pradesh - 533103, India
                  </p>
                </div>
                <div className="mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.51383991055!2d77.75665165!3d12.963630799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d94926cacf3%3A0x441b3f6f7d60b37!2sTiranga%20-%20Hyderabad%20Irani%20Chai!5e0!3m2!1sen!2sin!4v1716024230670!5m2!1sen!2sin"
                    width="92%"
                    height="150"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    // style={{ left: "13px", position: "absolute", top:'50' }}
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0.3s">
              <div
                class="single-feature-box"
                style={{ border: "2px solid lightblue" }}
              >
                <div>
                  <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  />
                </div>
                <div class="feature-title">
                  <h3>
                    {" "}
                    <i class="fas fa-map-marker-alt"></i>
                    {""} Malleswaram
                  </h3>
                </div>
                <div class="feature-details cursor-scale">
                  <h6
                    style={{
                      float: "left",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Address:
                  </h6>
                  <p>
                    D.No : 86-4-8/1, Tilak Road, Beside Lakshmi Dept Store,
                    Rajahmundry, East Godavari, Andhra Pradesh - 533103, India
                  </p>
                </div>
                <div className="mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.51383991055!2d77.75665165!3d12.963630799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d94926cacf3%3A0x441b3f6f7d60b37!2sTiranga%20-%20Hyderabad%20Irani%20Chai!5e0!3m2!1sen!2sin!4v1716024230670!5m2!1sen!2sin"
                    width="92%"
                    height="150"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    // style={{ left: "13px", position: "absolute", top:'50' }}
                  ></iframe>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 wow fadeInRight"
              data-wow-delay="0.5s"
            >
              <div
                class="single-feature-box"
                style={{ border: "2px solid lightblue" }}
              >
                <div>
                  <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  />
                </div>
                <div class="feature-title">
                  <h3>
                    {" "}
                    <i class="fas fa-map-marker-alt"></i>
                    {""} Electronic City
                  </h3>
                </div>
                <div class="feature-details cursor-scale">
                  <h6
                    style={{
                      float: "left",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Address:
                  </h6>
                  <p>
                    D.No : 86-4-8/1, Tilak Road, Beside Lakshmi Dept Store,
                    Rajahmundry, East Godavari, Andhra Pradesh - 533103, India
                  </p>
                </div>
                <div className="mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.51383991055!2d77.75665165!3d12.963630799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d94926cacf3%3A0x441b3f6f7d60b37!2sTiranga%20-%20Hyderabad%20Irani%20Chai!5e0!3m2!1sen!2sin!4v1716024230670!5m2!1sen!2sin"
                    width="92%"
                    height="150"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    // style={{ left: "13px", position: "absolute", top:'50' }}
                  ></iframe>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 wow fadeInRight"
              data-wow-delay="0.3s"
            >
              <div
                class="single-feature-box"
                style={{ border: "2px solid lightblue" }}
              >
                <div>
                  <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  />
                </div>
                <div class="feature-title">
                  <h3>
                    {" "}
                    <i class="fas fa-map-marker-alt"></i>
                    {""} HSR Layout
                  </h3>
                </div>
                <div class="feature-details cursor-scale">
                  <h6
                    style={{
                      float: "left",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Address:
                  </h6>
                  <p>
                    D.No : 86-4-8/1, Tilak Road, Beside Lakshmi Dept Store,
                    Rajahmundry, East Godavari, Andhra Pradesh - 533103, India
                  </p>
                </div>
                <div className="mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.51383991055!2d77.75665165!3d12.963630799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d94926cacf3%3A0x441b3f6f7d60b37!2sTiranga%20-%20Hyderabad%20Irani%20Chai!5e0!3m2!1sen!2sin!4v1716024230670!5m2!1sen!2sin"
                    width="92%"
                    height="150"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    // style={{ left: "13px", position: "absolute", top:'50' }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactus;
