import React from 'react';
import "./about.css";

const Aboutsection = () => {
    return (
        <div>

            <section class="about-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div className="aboutBox">
                                <div className="bgvideo">
                                    <video className="responsive-video" autoPlay muted loop>
                                        <source src="assets/images/irani.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div className="teacup">
                                    {/* Your overlay content goes here */}
                                    <img src='assets/images/24cup.png' alt="Teacup" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about_right">
                                <div class="comfy-section-title text-left wow fadeInRight" data-wow-delay="0.3s">
                                    <h5>About Us</h5>
                                    <h2>Our More Story</h2>
                                </div>
                                <div class="about-details wow fadeInRight" data-wow-delay="0.5s">
                                    <p class="text1">Tea is undeniably the most consumed beverage by all of us, including half a billion other Indians. Tea time is known for its unique blends of tea and consistent quality at an affordable price, all of which are valued the most by our customers and investors.</p>
                                    <p class="text2">We are here to bring the best experience that Tiranga hyderabadi irani tea gives to as many villages, towns, cities and countries as we can, while building sustainable growth for farmers and empowering local Entrepreneurship. We know the tea that Indians love, thats the taste we built. </p>
                                    <p class="text2">Tiranga hyderabadi irani tea house is remarkably known for its wide range of tea brands of India with best quality in every granule. We are spread into cluster of tea outlets extending variety of tea, coolers and milkshakes that can change your mood in seconds. Taste our quality rich products at affordable prices.. </p>
                                </div>
                                <div class="section-button wow fadeInRight" data-wow-delay="0.7s">
                                    <a href="/about">Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section class="about-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="about-thumb">
                                <div class="box-about" >
                                    <div class="container">
                                        <iframe class="responsive-iframe" width="100%" height="400px" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}
        </div>
    );
}

export default Aboutsection;
