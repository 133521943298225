// import React, { useEffect } from 'react';
// const Header = () => {
//   useEffect(() => {
//     const header = document.getElementById("sticky-header");
//     const sticky = header.offsetTop;

//     const handleScroll = () => {
//       if (window.pageYOffset > sticky) {
//         header.classList.add("sticky");
//       } else {
//         header.classList.remove("sticky");
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header id="sticky-header" style={{borderBottom:"5px solid #254B77"}} className="comfy_nav_manu meanmenu-reveal">
//       <div className="container">
//         <div className="row align-items-center">
//           <div className="col-lg-2" style={{height:"130px",position:"absolute",zIndex:"1"}}>
//             <div className="logo">
//               <a className="logo_img"  href="/" title="comfy">
//                 <img src="assets/images/tiranga-logo.png" width="100px" alt="logo"/>
//                 {/* <img src="assets/images/tiranga-logo.png" alt="logo"/> */}
//               </a>
//               <a className="main_sticky" href="/" title="comfy">
//                 {/* <img src="assets/images/logo2.png" alt="logo"/> */}
//                 <img src="assets/images/tiranga-logo.png" style={{width:"150px",height:"150px", marginTop:"30px"}}  alt="logo"/>
//               </a>
//             </div>
//           </div>
//           <div className="col-lg-10">
//             <nav className="comfy_menu">
//               <ul className="nav_scroll">
//                 <li className="active"><a href="/">Home </a></li>
//                 <li><a href="/about">About</a></li>
//                 <li><a href="/franchise">Franchise  </a></li>
//                 <li><a href="/Our-gallery">Gallery</a></li>
//                 <li><a href="/Our-branches">Branches</a></li>
//                 <li><a href="/contacts">Contact</a></li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;

import React, { useEffect } from "react";
import "./header.css";

const Header = () => {
  useEffect(() => {
    const navbar = document.getElementById("navbar");
    const sticky = navbar.offsetTop;

    const handleScroll = () => {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header className="header-main-wrap">
        <nav
          className="navbar navbar-expand-lg"
          id="navbar"
          style={{ borderBottom: "5px solid #254B77", height: "83px" }}
        >
          <div className="container">
            <div
              className="logo-container"
              style={{
                position: "relative",
                width: "150px",
                height: "150px",
                top: "39px",
              }}
            >
              <a href="/">
                <img
                  src="assets/images/logo.png"
                  alt="logo"
                  style={{ width: "100%", height: "150px", zIndex: 1 }}
                />
              </a>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarTogglerDemo02"
              style={{ float: "right", marginLeft: "100px" }}
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active nav-item-hover"
                    aria-current="page"
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active nav-item-hover"
                    aria-current="page"
                    href="/about"
                  >
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/franchise"
                  >
                    Franchise
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/our-gallery"
                  >
                    Gallery
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/Our-branches"
                  >
                    Branches
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/contacts"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
