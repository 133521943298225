import React from 'react';
import Hero from './Hero';
import Features from './Features';
import Aboutsection from './Aboutsection';
import Menusection from './Menusection';
import Portfoliosection from './Portfoliosection';
// import Teamsection from './Teamsection';
import Newsletter from './Newsletter';
import Maps from './Maps';

const Blog = () => {
  return (
    <div>
       <Hero/>
      <Features/>
      <Aboutsection/>
      <Menusection/>
      <Portfoliosection/>
      {/* <Teamsection/> */}
      {/* <Newsletter/> */}
      {/* <Maps/> */}
    </div>
  );
}

export default Blog;
