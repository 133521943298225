import React, { useRef, useEffect } from 'react';
import Swiper from 'swiper';
// import 'swiper/swiper-bundle.min.css'; // Adjusted import statement for Swiper CSS
// import './Hero.css'; // Import your CSS file

const Hero = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiper = new Swiper(swiperRef.current, {
      // Swiper options here
      loop: true,
      autoplay: {
        delay: 1000, // 5 seconds delay between slides
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <div className="hero-slider"  ref={swiperRef}>
      <div className="swiper-wrapper">

        <div className="swiper-slide">
          <section className="hero-section"  >
            <div className="container">
              <div className="row hero-bg" style={{marginTop:'100px'}}>
                <div className="col-lg-6">
                  <div className="hero-content">
                    {/* <h5>Tiranga Chai House</h5> */}
                    <h2 style={{fontSize:'26px'}}>Enjoy Your Morning with </h2>
                    <h2>Hyderabadi Irani chai</h2>
                    <p> A rich and aromatic blend of tea infused with spices, served with milk and typically accompanied by sweet biscuits.</p>
                    <div className="slider-button">
                      <div className="section-button"><a href="#">Enquire Now </a></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-thumb">
                    <img src="assets/images/slider/hero-thumb2.png"  alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="swiper-slide">
          <section className="hero-section">
            <div className="container">
              <div className="row hero-bg" style={{marginTop:'100px'}}>
                <div className="col-lg-6">
                  <div className="hero-content">
                    {/* <h5>Black coffee is awesome</h5> */}
                    <h2 style={{fontSize:'26px'}}>Tiranga Irani Chai house Opens</h2>
                    <h2>Early Today</h2>
                    <p>A versatile beverage brewed from roasted coffee beans, delivering a bold flavor profile ranging from rich and robust to smooth and mellow.</p>
                    <div className="slider-button">
                      <div className="section-button"><a href="#">Enquire Now</a></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hero-thumb">
                    <img src="assets/images/slider/hero-thumb.png"style={{ borderRadius:"50px",height:"60vh",border:"3px solid white"}} alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>


      </div>

      <div className="swiper-pagination"></div>
      <div className="swiper-button-prev d-none d-md-flex"></div>
      <div className="swiper-button-next d-none d-md-flex"></div>
    </div>
  );
}

export default Hero;
