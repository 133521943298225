import React from "react";

const Franchise = () => {
  return (
    <div>
      <section class="breadcumb-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcumb-title" style={{ textAlign: "center", marginTop:'80px' }}>
                <h2 style={{ fontWeight: "600", fontSize: "36px" }}>
                Franchise
                </h2>
              </div>
              <div class="breadcumb-inner" style={{ textAlign: "center" }}>
                <ul>
                  <li>
                    <a href="/" style={{ fontWeight: "600" }}>
                      Home
                    </a>
                  </li>
                  <li>
                    <i class="fa fa-angle-right"></i>
                  </li>
                  <li style={{ fontWeight: "600" }}>Franchise</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Start Contact Section  --> */}
      <div class=""  >
        <div class="container mt-5 mb-5">
          <div class="row">
            <div class="col-lg-6">
            {/* <img class="wow fadeInLeft" data-wow-delay="0.3s" src="assets/images/resource/dtls.jpg"  alt=""  style={{height:'100vh', width:'90%'}}/> */}
            <img class="wow fadeInLeft" data-wow-delay="0.3s" src="assets/images/franchise/fr3.jpeg"  alt=""  style={{height:'95vh', width:'90%',borderRadius:'32px'}}/>
            </div>
            <div class="col-lg-6">
              <div class="contact_from_box">
                <div class="comfy-section-title text-left">
                  {/* <h5>FRANCHISE ENQUIRY!!!</h5> */}
                  <h1>FRANCHISE ENQUIRY!!!</h1>
                  <p>Please Contact us below</p>
                </div>
                <form
                  class="dreamit-form"
                  action="https://formspree.io/f/myyleorq"
                  method="POST"
                >
                  <div class="form-box">
                    <input
                      id="name2"
                      type="text"
                      name="name"
                      placeholder="Name:"
                      autocomplete="on"
                      required
                    />
                  </div>
                  <div class="form-box">
                    <input
                      id=""
                      type="number"
                      name="name"
                      placeholder="Mobile Number:"
                      autocomplete="on"
                    />
                  </div>
                  <div class="form-box">
                    <input
                      id="emal2"
                      type="email"
                      name="name"
                      placeholder="Email:"
                      autocomplete="on"
                    />
                  </div>
                  <div class="form-box">
                    <input
                      id="address"
                      type="address"
                      name="address"
                      placeholder="Address:"
                      autocomplete="on"
                    />
                  </div>
                  <div class="form-box upp">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      placeholder="Your Message"
                      autocomplete="off"
                    ></textarea>
                  </div>
                  <div class="contact-form">
                    <button type="submit"> Be the Franchise</button>
                  </div>
                </form>
                <div class="status"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="about-section style-two">
        <div class="container">
          <div class="row">
           
            <div class="col-lg-6">
              <div class="about_right">
                <div class="comfy-section-title text-left wow fadeInDown">
                  <h5>About Franchise</h5>
                  <h2  style={{fontWeight:'600', fontSize:'32px'}}>Here is the best Franchise</h2>
                  <h2  style={{fontWeight:'600', fontSize:'32px'}}> Opportunity from us</h2>
                </div>
                <div class="about-details wow fadeInRight">
                  <p class="text1">
                    We appreciate your interest for a committed collaboration
                    with Tiranga Hyderabadi Irani Chai and its franchise
                    opportunities. Our vision is to reach to all tea lovers and
                    make them taste the best variants of tea globally. If not
                    lovers, we will surely turn them into tea lovers with our
                    authentic variants of coolers and shakes. We are looking for
                    dedicated and pledged entrepreneurs who wish to connect with
                    our franchises. We believe in laying a strong foundation
                    from the time of ideation to building of strong franchises.
                    We are always up for small scale businesses and try to
                    promote them in every way possible. Similarly, we encourage
                    in extending proficient business opportunities.
                  </p>
                  <p class="text2">
                    Our doors will always be open for competent entrepreneurs
                    who are capable of expanding the chain of franchises and
                    strive towards the development of the company. You can take
                    the role of an ambassador in promoting our culture of tea
                    and help in serving our customers with premium milkshakes
                    and coolers. Join us today and see the magic!{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow fadeInLeft">
              <div class="about-thumb mt-5 pt-5">
                <img src="assets/images/franchise/fr1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="about-section style-two">
        <div class="container">
          <h2  style={{fontWeight:'600', fontSize:'32px'}}>Franchise Requirement</h2>
          <div class="row">
            <div class="col-lg-6 wow fadeInLeft">
              <div class="accordion mt-5" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What is the size of the shop required for starting a TEA
                      HOUSE Unit Franchise?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The minimum size of the shop should be 100 sq. ft. But the
                      ideal space would be of 150 sq.ft.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What is the franchise fee?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The unit franchise fee is Rs. 3,25,000/-, marketing fee
                      and the cost of all equipments such as hot snacks case,
                      refrigerator, deep freezer, induction stove, high pressure
                      gas stove, fruit mixer, tea utensils, tea powder, branded
                      glasses, name boards, display menu, lollipop board,
                      decoration items, photo frames etc and an initial stock.
                      (Please check Annexure - 1 (page 5) for the complete
                      details of the startup kit){" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Are the equipments provided (fridge, freezer, etc.)
                      branded?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Yes. All are branded products (Hair, Voltas etc.) and
                      comes with ensured warranty.{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What are the extra expenses that can come apart from the
                      franchise fee of 3.25 lakhs?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The shop should have tile flooring, kitchen partition, tea
                      counter, etc. The expenses for these will be extra. The
                      franchise is responsible for any interior work, painting,
                      and furniture. (The approximate cost will come around Rs.
                      30,000/- to Rs.50, 000/-) Real estate charges like shop
                      rent and security amount will also be extra
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Is the franchise fee refundable?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The franchise fee is not refundable{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSix">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      How long is the franchise agreement? How can it be
                      renewed?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The franchise agreement is valid for 5 years. At the end
                      of the 5th year, you can renew the agreement for another 5
                      years by paying 10% of the franchise fee.{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSeven">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Will the brand provide stock for preparing everything in
                      the menu when the initial stock is over?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The brand will supply all the major ingredients. But there
                      are some items which the franchisee has to buy locally.
                      Please check Annexure - 2 (page 7) for the list of items
                      supplied by the brand and the price. Please check Annexure
                      - 3 (page 8) for the list of items the franchisee has to
                      buy from the local market{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingEight">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      What are the snack items supplied by the brand?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      Brand supplies only cookies like Osmania Biscuits. The
                      franchisee can keep the local snack items at the shop with
                      the approval from the brand. The franchisee can either
                      make the snacks or get it from good snacks vendors. The
                      quality of the snacks should not be compromised{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingNine">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      Can the franchise add extra items other than the snacks in
                      the menu?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseNine"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingNine"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      TEA HOUSE does not entertain any items other than its menu
                      to be sold from its outlet. However, considering the
                      regional flavor and demands, a few selected items can be
                      added with the written approval from the brand. No other
                      tea varieties can be added. TEA HOUSE ensures that none of
                      the added items damage its brand value.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow fadeInRight">
               <div class="accordion mt-5" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwenty">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwenty"
                      aria-expanded="false"
                      aria-controls="collapseTwenty"
                    >
                      Will the brand provide staff?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwenty"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwenty"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      No. However, the brand provides training to your selected
                      staff. You need a minimum of 2 workers for the shop. It is
                      not necessary that these workers have any prior experience
                      in the hotel field. The reason is that all the drinks at
                      TEA HOUSE are prepared using formulae. With 3 days of
                      training, we can teach anyone; a fresher or an experienced
                      one. Since we are using a formula to make drinks, a person
                      with an experience of 1 week or 1 year, can prepare the
                      items of the same taste and quality. A new employee can be
                      trained in 3 days, if an old employee leaves the job.{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      Will the training be charged?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      we can send one of our chefs to your shop for training for
                      3 days, which will cost is Free- and you have to arrange
                      the expenses of travel, food and accommodation for the
                      trainer.{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingEleven">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >
                      What is the average daily sale at the existing store?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseEleven"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingEleven"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      All our outlets are having a daily sale between 5000 to
                      30000 per day. It completely depends on the location.{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwelve">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwelve"
                      aria-expanded="false"
                      aria-controls="collapseTwelve"
                    >
                      What is the monthly royalty amount payable to the brand?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseTwelve"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwelve"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      You don’t need to pay any royalty for the brand.{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThirteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThirteen"
                      aria-expanded="false"
                      aria-controls="collapseThirteen"
                    >
                      Does the brand have a centralized billing system?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseThirteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThirteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      No. We don’t have a centralized billing system. There are
                      certain practical issues in applying the billing system
                      for the time being. However, the brand is working on the
                      same to find a convenient solution.{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFourteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourteen"
                      aria-expanded="false"
                      aria-controls="collapseFourteen"
                    >
                      What should be the ideal the distance between TEA HOUSE
                      shops?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFourteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFourteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      There is no specified distance. We'll give shop at a place
                      where your business will not be affected because of the
                      new shop. For, e.g., 2 shops located on both sides of the
                      highway have different customers. However, if we get an
                      opportunity to give an outlet near an existing one, the
                      existing franchisee will be given that opportunity first.{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFifteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFifteen"
                      aria-expanded="false"
                      aria-controls="collapseFifteen"
                    >
                      What is the process if an investor is interested to start
                      the TEA HOUSE Franchise?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseFifteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFifteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      The investor has to pay a token amount as confirmation;
                      the investor can decide the said amount. A Letter Of
                      Intent (LOI) will be signed against this amount and a
                      payment confirmation will be given to the investor. The
                      balance amount should be paid at least 10 days prior to
                      the opening of the outlet. The agreement will be signed at
                      the time of full and final payment settlement. The brand
                      will supply all the items including the start up kit, one
                      week prior to the outlet opening. The franchisee has to
                      inform the brand regarding the opening of the outlet at
                      least 10 days before so that the brand can ensure the
                      availability of the chef for the training{" "}
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSixteen">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixteen"
                      aria-expanded="false"
                      aria-controls="collapseSixteen"
                    >
                      Can the franchisee use its own glasses for serving teas
                      and other items?{" "}
                    </button>
                  </h2>
                  <div
                    id="collapseSixteen"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingSixteen"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      No. It is strictly not allowed. The franchisee has to sell
                      all the items in TEA HOUSE glasses only. However, if a
                      customer specifically asks for other drinking glasses,
                      exceptions can be made. The numbers can be hardly one or
                      two in a month.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Franchise;
