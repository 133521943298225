import React, {useEffect} from "react";

const Footer = () => {
    useEffect(() => {
        const progressIndicator = document.querySelector(".prgoress_indicator");
        if (progressIndicator) {
          progressIndicator.addEventListener("click", () => {
            window.scrollTo({
              top: 0,
              behavior: "smooth" // Smooth scrolling animation
            });
          });
        }
      }, []); // Empty dependency array ensures this effect runs only once after the component mounts
  return (
    <div>
      <footer class="footer-section">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div class="widget widgets-company-info">
                <div class="dreamhub-logo">
                  <a class="logo_thumb" href="/" title="dreamhub">
                    {/* <img src="assets/images/logo.png" alt="" /> */}
                    <img src="assets/images/tiranga-logo.png" alt="logo" style={{width:'50%'}}/>
                  </a>
                </div>
                <div class="company-info-desc">
                  <p>
                    {" "}
                    Tiranga Hyderabadi Irani Chai is one of the largest tea brands of India, having a chain of tea outlets selling different kinds of TEA, Coffee & ice-creams at reasonable prices.{" "}
                  </p>
                </div>
                <div class="follow-company-icon">
                  <a href="#">
                    {" "}
                    <i class="fab fa-facebook-f"></i>{" "}
                  </a>
                  <a href="#">
                    {" "}
                    <i class="fab fa-twitter"></i>{" "}
                  </a>
                  <a href="#">
                    {" "}
                    <i class="fab fa-instagram"> </i>{" "}
                  </a>
                  <a href="#">
                    {" "}
                    <i class="fab fa-linkedin"></i>{" "}
                  </a>
                  {/* <a href="#">
                    {" "}
                    <i class="fab fa-behance"></i>{" "}
                  </a> */}
                </div>
              </div>
            </div>
            <div
              class="col-lg-2 col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div class="widget widget-nav-menu">
                <h4 class="widget-title">Quick Link</h4>
                <div class="menu-quick-link-content">
                  <ul class="footer-menu">
                    <li>
                      <a href="/about"> About Us </a>
                    </li>
                    <li>
                      <a href="/franchise"> Franchise </a>
                    </li>
                    <li>
                      <a href="/Our-gallery"> Our Gallery  </a>
                    </li>
                    <li>
                      <a href="/Our-branches"> Branches </a>
                    </li>
                    <li>
                      <a href="/contacts"> Contacts </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay="0.6s"
            >
              <div class="widget widget-nav-menu">
                <h4 class="widget-title"> Contact Us </h4>
                <div class="menu-quick-info-content">
                  <ul class="footer-info">
                    <li>
                      <span>
                        <i class="fas fa-phone-alt"></i>Phone Number
                      </span>
                      <br />
                      <a href="callto:+917090466999">+91 7090466999 </a>
                    </li>
                    <li>
                      <span>
                        <i class="far fa-envelope"></i>Locations
                      </span>
                      <br />
                      <span >  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;Leelavati Achar Complex, <br/> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Immadihalli Road, Whitefield, <br/>  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Bangalore-560066. </span>
                    </li>
                    <li>
                      <span>
                        <i class="fas fa-map-marker-alt"></i>Email Address
                      </span>
                      <br />
                      <a href="#">info@tirangachaihouse.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp"
              data-wow-delay="0.8s"
            >
              <div class="widget-nav-menu">
                <h4 class="widget-title"> Join Our Mailing List </h4>
                <div class="menu-quick-link-content">
                  <div class="single-widget-item">
                    <div class="subscribe-bg">
                      <form
                        action="https://formspree.io/f/myyleorq"
                        method="POST"
                        class="dreamit-form2"
                      >
                        <div class="subscribe-form">
                          <input
                            id="email2"
                            type="Email"
                            name="Email"
                            placeholder="Enter Email"
                            autocomplete="on"
                          />
                          <button class="btn btn_primary" type="submit">
                            Subscribe
                          </button>
                        </div>
                      </form>
                      <div class="status2"></div>
                    </div>
                    <p>
                    Explore handpicked specialty coffee.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div class="footer-bottom">
        <div class="container-fluid p-0">
          <div class="row footer-bg">
            <div class="col-lg-12 col-md-12">
              <div class="footer-bottom-content">
                <p>© 2024 All Rights Reserved @ Tiranga Irani Chai House</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Start scrollup section --> */}
      {/* <!-- ========================== --> */}
      {/* <div class="prgoress_indicator active-progress">
        <svg
          class="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919, 307.919",
              strokeDashoffset: "282.865",
            }}
          ></path>
        </svg>
      </div> */}
      <div className="prgoress_indicator active-progress">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear 0s",
              strokeDasharray: "307.919, 307.919",
              // strokeDashoffset: "282.865",
            }}
          ></path>
        </svg>
      </div>

      {/* <!-- ========== Cursor ========== --> */}
      {/* <div class="cursor"></div> */}
      {/* <div class="cursor2"></div> */}
      {/* <!-- ========== End Cursor ========== --> */}
    </div>
  );
};

export default Footer;
