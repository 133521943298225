import React from "react";

const About = () => {
  return (
    <div>
      {/* <!-- Start About Breadcumb Section  --> */}
      <section class="breadcumb-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcumb-title" style={{ textAlign: "center", marginTop:'80px' }}>
                <h2 style={{ fontWeight: "600", fontSize: "36px" }}>
                  About Us
                </h2>
              </div>
              <div class="breadcumb-inner" style={{ textAlign: "center" }}>
                <ul>
                  <li>
                    <a href="/" style={{ fontWeight: "600" }}>
                      Home
                    </a>
                  </li>
                  <li>
                    <i class="fa fa-angle-right"></i>
                  </li>
                  <li style={{ fontWeight: "600" }}>About</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Start Comfy About Section  --> */}
      <section class="about-section style-four">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 wow fadeInDown">
              <div class="comfy-section-title">
                <div class="section-icon">
                  <div class="section-icon">
                    <img
                      src="assets/images/feature/feature.png"
                      alt=""
                      style={{ width: "5%" }}
                    />
                  </div>
                </div>
                <h5>About Us</h5>
                <h2 style={{ fontWeight: "600", fontSize: "32px" }}>
                  Welcome To Irani Chai House
                </h2>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 wow fadeInLeft">
              <div class="single-about-box">
                <div class="about-thumb2">
                  <img src="assets/images/about/about-1.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 wow fadeInRight">
              <div class="single-about-box">
                <div class="about-thumb2">
                  <img src="assets/images/about/about-2.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="blogs-section style-three">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="row blogs-pr">
                <div class="single-blog-dtls-box wow fadeInLeft">
                  <div className="row">
                    <div className="col-lg-5">
                      <div class="blog-thumb ">
                        <img
                          // src="assets/images/resource/blogs2.jpg"
                          src="assets/images/about/shop.jpg"
                          alt=""
                          style={{ height: "70vh", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 wow fadeInRight">
                      <div class="blog-content "> 
                        <h2 class="blog-title" style={{color:'var(--primary-color)'}}>
                          
                            About Tiranga Chai House
                        </h2>
                        <p class="blog-desc2">
                        Tiranga Chai House is remarkably known for its wide range of tea brands of India with best quality in every granule. We are spread into cluster of tea outlets extending variety of tea, coolers and milkshakes that can change your mood in seconds. Taste our quality rich products at affordable prices.
                        </p>
                        <h4 class="blog-title" style={{color:'var(--primary-color)'}}>
                          
                        OUR KIND OF UNIQUENESS                        </h4>
                        <p>
                        We differ from others by our unique and authentic ingredients. Every sip you drink is a drop of purity and quality. We process tea and other ingredients under hygiene conditions and see that there is a quality check before it is out for our chai lovers. We have our very own processing unit at Rajahmundry and have chain of outlets that exclusively serve the best tea in India. We sell the best tea that relishes your taste buds. We strive hard to put forth best efforts to introducing variety of tea which are both healthier and tastier. The more you taste, the more you wish to have from Tiranga Chai House.
                        </p>

                        <h4 class="blog-title" style={{color:'var(--primary-color)'}}>
                          
                        YOUR KIND OF EXPERIENCE                      </h4>

                        <p class="blog-desc2">
                        We extract from the best tea estates and bring to you the best flavours of different regions. The moment you taste our tea, you lose yourself in experiencing the rich tradition of tea. We are enthusiastic to make you taste the best tea, milkshakes and coolers during every season.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- contact form box --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="blogs-section style-three">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="row blogs-pr">
                <div class="single-blog-dtls-box wow fadeInLeft">
                  <div className="row">
                  <div className="col-lg-8">
                      <div class="blog-content">
                        <h2 class="blog-title" style={{color:'var(--primary-color)'}}>
                            Things you didn’t know about Irani Tea
                        </h2>
                        <p class="blog-desc2">
                        Irani Tea is a blend of several spices including lemongrass, ginger, back peper and more making it's usage enriching. Enrich your beloved tea with condensed milk and fresh cream to create a modern twist on classic Iranian style tea.
                        </p>
                        <p>
                        Irani Chai is traditionally known for its strong spices. 4. The Sweetness: Irani Chai is sweetened with generous amounts of sugar, providing the perfect balance to the bold flavors of the tea and spices.
                        </p>


                        <p class="blog-desc2">
                        The Iranian migrants have set up Irani Cafes in the city, and the tea served here came to be known as Irani Chai. Tea in Iran is usually drunk black but the irani chai of half decoction and half milk represents the wonders and deliciousness of evolving and combining cultures.
                        </p>
                        <p class="blog-desc2">
                        Irani chai is a unique form of tea unlike any other version of Indian chai, made by the addition of mawa or khoya to black tea. The result is a sweet and creamy chai—the addition of spices like cinnamon and green cardamom is optional.
                        </p> <p class="blog-desc2">
                        The standard ingredients used to make a Irani Chai are loose leaf tea/ dust, a milk product, cardamoms, and sugar. Some versions also use a special Chai Spice blend. There are not many ways to make Irani Chai, but the milk product used varies from region to region.
                        </p>
                       
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div class="blog-thumb ">
                        <img
                          // src="assets/images/resource/blogs2.jpg"
                          src="assets/images/about/irani-chai-1.png"
                          alt=""
                          style={{ height: "70vh", width: "100%" }}
                        />
                      </div>
                    </div>
                   
                  </div>
                  {/* <!-- contact form box --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
