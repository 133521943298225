import React from "react";

const Gallery = () => {
  return (
    <div>
      {/* <!-- Start About Breadcumb Section  --> */}
      <section class="breadcumb-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcumb-title" style={{ textAlign: "center", marginTop:'80px' }}>
                <h2 style={{ fontWeight: "600", fontSize: "36px" }}>Gallery</h2>
              </div>
              <div class="breadcumb-inner" style={{ textAlign: "center" }}>
                <ul>
                  <li>
                    <a href="/" style={{ fontWeight: "600" }}>
                      Home
                    </a>
                  </li>
                  <li>
                    <i class="fa fa-angle-right"></i>
                  </li>
                  <li style={{ fontWeight: "600" }}>Gallery</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="portfolio-section">
        <div class="container mt-5 mb-5">
          <div class="row">
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-1.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-2.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-3.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-4.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="container mt-5 mb-5">
          <div class="row">
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-5.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-6.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-1.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-2.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="container mt-5 mb-5">
          <div class="row">
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-3.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-4.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-5.jpg" alt="" />
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <img src="assets/images/portfolio/portfolio-6.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
