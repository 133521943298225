import React from "react";
import Header from "./Compoents/Header";
import Footer from "./Compoents/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blog from "./Compoents/Blog";
import About from "./Compoents/Pages/About";
import Contactus from "./Compoents/Pages/Contactus";
import Franchise from "./Compoents/Pages/Franchise";
import Gallery from "./Compoents/Pages/Gallery";
import Branches from "./Compoents/Pages/Branches";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Blog />} />
            <Route path="/about" element={<About />} />
            <Route path="/franchise" element={<Franchise />} />
            <Route path="/Our-gallery" element={<Gallery />} />
            <Route path="/Our-branches" element={<Branches />} />
            <Route path="/contacts" element={<Contactus />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
