import React from "react";

const Branches = () => {
  return (
    <div>
      <section class="breadcumb-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcumb-title" style={{ textAlign: "center", marginTop:'80px' }}>
                <h2 style={{ fontWeight: "600", fontSize: "36px" }}>
                Our Branches
                </h2>
              </div>
              <div class="breadcumb-inner" style={{ textAlign: "center" }}>
                <ul>
                  <li>
                    <a href="/" style={{ fontWeight: "600" }}>
                      Home
                    </a>
                  </li>
                  <li>
                    <i class="fa fa-angle-right"></i>
                  </li>
                  <li style={{ fontWeight: "600" }}>Our Branches</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="feature-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 wow fadeInDown">
              <div class="comfy-section-title">
                <div class="section-icon">
                  {/* <img src="assets/images/resource/section-icon.png" alt="" /> */}
                  <div class="section-icon"><img src="assets/images/feature/feature.png" alt="" style={{width:'5%'}}/></div>

                </div>
                <h5>Our Branches</h5>
                <h2 style={{fontWeight:'600', fontSize:'32px'}}>We available at all locations in Bangalore</h2>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0.3s">
              <div
                class="single-feature-box"
                style={{ border: "2px solid lightblue" }}
              >
                <div>
                  <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  />
                </div>
                <div class="feature-title">
                  <h3>
                    {" "}
                    <i class="fas fa-map-marker-alt"></i>
                    {""} Indiranagar
                  </h3>
                </div>
                <div class="feature-details cursor-scale">
                  <h6 style={{ float: "left", fontWeight:'600', fontSize:'18px' }}>Address:</h6>
                  <p>
                    D.No : 86-4-8/1, Tilak Road, Beside Lakshmi Dept Store,
                    Rajahmundry, East Godavari, Andhra Pradesh - 533103, India
                  </p>
                </div>
                <div className="mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.51383991055!2d77.75665165!3d12.963630799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d94926cacf3%3A0x441b3f6f7d60b37!2sTiranga%20-%20Hyderabad%20Irani%20Chai!5e0!3m2!1sen!2sin!4v1716024230670!5m2!1sen!2sin"
                    width="92%"
                    height="150"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    // style={{ left: "13px", position: "absolute", top:'50' }}
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0.3s">
              <div
                class="single-feature-box"
                style={{ border: "2px solid lightblue" }}
              >
                <div>
                  <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  />
                </div>
                <div class="feature-title">
                  <h3>
                    {" "}
                    <i class="fas fa-map-marker-alt"></i>
                    {""} Malleswaram
                  </h3>
                </div>
                <div class="feature-details cursor-scale">
                  <h6 style={{ float: "left", fontWeight:'600', fontSize:'18px' }}>Address:</h6>
                  <p>
                    D.No : 86-4-8/1, Tilak Road, Beside Lakshmi Dept Store,
                    Rajahmundry, East Godavari, Andhra Pradesh - 533103, India
                  </p>
                </div>
                <div className="mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.51383991055!2d77.75665165!3d12.963630799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d94926cacf3%3A0x441b3f6f7d60b37!2sTiranga%20-%20Hyderabad%20Irani%20Chai!5e0!3m2!1sen!2sin!4v1716024230670!5m2!1sen!2sin"
                    width="92%"
                    height="150"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    // style={{ left: "13px", position: "absolute", top:'50' }}
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInRight" data-wow-delay="0.5s">
              <div
                class="single-feature-box"
                style={{ border: "2px solid lightblue" }}
              >
                <div>
                  <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  />
                </div>
                <div class="feature-title">
                  <h3>
                    {" "}
                    <i class="fas fa-map-marker-alt"></i>
                    {""} Electronic City
                  </h3>
                </div>
                <div class="feature-details cursor-scale">
                  <h6 style={{ float: "left", fontWeight:'600', fontSize:'18px' }}>Address:</h6>
                  <p>
                    D.No : 86-4-8/1, Tilak Road, Beside Lakshmi Dept Store,
                    Rajahmundry, East Godavari, Andhra Pradesh - 533103, India
                  </p>
                </div>
                <div className="mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.51383991055!2d77.75665165!3d12.963630799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d94926cacf3%3A0x441b3f6f7d60b37!2sTiranga%20-%20Hyderabad%20Irani%20Chai!5e0!3m2!1sen!2sin!4v1716024230670!5m2!1sen!2sin"
                    width="92%"
                    height="150"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    // style={{ left: "13px", position: "absolute", top:'50' }}
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInRight" data-wow-delay="0.3s">
              <div
                class="single-feature-box"
                style={{ border: "2px solid lightblue" }}
              >
                <div>
                  <img
                    src="assets/images/branches/branch1.jfif"
                    onload="SVGInject(this)"
                    alt=""
                  />
                </div>
                <div class="feature-title">
                  <h3>
                    {" "}
                    <i class="fas fa-map-marker-alt"></i>
                    {""} HSR Layout
                  </h3>
                </div>
                <div class="feature-details cursor-scale">
                  <h6 style={{ float: "left", fontWeight:'600', fontSize:'18px' }}>Address:</h6>
                  <p>
                    D.No : 86-4-8/1, Tilak Road, Beside Lakshmi Dept Store,
                    Rajahmundry, East Godavari, Andhra Pradesh - 533103, India
                  </p>
                </div>
                <div className="mt-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15552.51383991055!2d77.75665165!3d12.963630799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0d94926cacf3%3A0x441b3f6f7d60b37!2sTiranga%20-%20Hyderabad%20Irani%20Chai!5e0!3m2!1sen!2sin!4v1716024230670!5m2!1sen!2sin"
                    width="92%"
                    height="150"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    // style={{ left: "13px", position: "absolute", top:'50' }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Branches;
