import React from 'react';

const Menusection = () => {
  return (
    <div>
        <section class="menu-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 wow fadeInDown">
                    <div class="comfy-section-title">
                        {/* <div class="section-icon"><img src="assets/images/resource/section-icon.png" alt=""/></div> */}
                        <div class="section-icon"><img src="assets/images/feature/feature.png" alt="" style={{width:'5%'}}/></div>

                        <h5>Our Menu</h5>
                        <h2  style={{fontWeight:'600', fontSize:'32px'}}>What We Have Inside </h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row wow fadeInLeft" data-wow-delay="0.3s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/irani-tea.jpg" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Irani Tea <span></span></h3><span class="menu-price">₹ 15.00</span>
                                </div>
                                <p class="menu-text">Fragrant Spiced Persian Tea Tradition</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInLeft" data-wow-delay="0.5s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/coffee.png" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Coffee <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Rich Aromatic Energizing Morning Brew</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInLeft" data-wow-delay="0.7s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/bellam-tea.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Bellam Tea <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Sweet Traditional Jaggery Indian Tea</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInLeft" data-wow-delay="0.7s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/ginger-tea.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Ginger Tea <span></span></h3><span class="menu-price">₹ 15.00</span>
                                </div>
                                <p class="menu-text">Spicy Invigorating Comforting Herbal Infusion</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInLeft" data-wow-delay="0.7s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/milk.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Milk <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Creamy Nourishing Essential Dairy Beverage</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInRight" data-wow-delay="0.7s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/sonti-tea.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Sonti Tea <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Zesty Herbal Ginger Infusion</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row wow fadeInRight" data-wow-delay="0.3s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/badam-milk.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Badam Milk <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Nutty Creamy Delightful Almond Beverage</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInRight" data-wow-delay="0.5s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/horlicks.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Horlicks <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Nutritious Malted Milk Beverage</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInRight" data-wow-delay="0.7s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/boost.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Boost <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Energizing Nutritional Drink Supplement</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInRight" data-wow-delay="0.7s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/lemon-tea.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Lemon Tea <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Zesty Citrus Herbal Infusion</p>
                            </div>
                        </div>
                    </div>
                    <div class="row wow fadeInRight" data-wow-delay="0.7s">
                        <div class="menu-single-box">
                            <div class="menu-thumb">
                                <img src="assets/images/menu/green-tea.jfif" alt=""/>
                                {/* <button class="product-action-btn" data-tooltip-text="Quick View" data-bs-toggle="modal" data-bs-target="#exampleProductModal"><i class="bi bi-search"></i></button> */}
                            </div>
                            <div class="menu-content">
                                <div class="menu-title">
                                    <h3>Green Tea <span></span></h3><span class="menu-price">₹ 20.00</span>
                                </div>
                                <p class="menu-text">Revitalizing Herbal Antioxidant Elixir</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Menusection;

