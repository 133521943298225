import React from 'react';

const Portfoliosection = () => {
  return (
    <div>
       <section class="portfolio-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="portfolio-single-box mt-5">
                        <div class="portfolio-thumb">
                            <img src="assets/images/portfolio/portfolio-1.jpg" alt=""/>
                        </div>
                        <div class="portfolio-content overlay">
                            <h3>Brewing Guide</h3>
                            <p>Build websites with no coding skills,
                                tools are drag & drop, intuitive, easy
                                to use and understand</p>
                            <div class="portfolio-button">
                                <a href="#!">Read More <i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="portfolio-single-box mt-5">
                        <div class="portfolio-thumb">
                            <img src="assets/images/portfolio/portfolio-2.jpg" alt=""/>
                        </div>
                        <div class="portfolio-content overlay">
                            <h3>Brewing Guide</h3>
                            <p>Build websites with no coding skills,
                                tools are drag & drop, intuitive, easy
                                to use and understand</p>
                            <div class="portfolio-button">
                                <a href="#!">Read More <i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="portfolio-single-box mt-5">
                        <div class="portfolio-thumb">
                            <img src="assets/images/portfolio/portfolio-3.jpg" alt=""/>
                        </div>
                        <div class="portfolio-content overlay">
                            <h3>Brewing Guide</h3>
                            <p>Build websites with no coding skills,
                                tools are drag & drop, intuitive, easy
                                to use and understand</p>
                            <div class="portfolio-button">
                                <a href="#!">Read More <i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="portfolio-single-box mb-5">
                        <div class="portfolio-thumb">
                            <img src="assets/images/portfolio/portfolio-4.jpg" alt=""/>
                        </div>
                        <div class="portfolio-content overlay">
                            <h3>Brewing Guide</h3>
                            <p>Build websites with no coding skills,
                                tools are drag & drop, intuitive, easy
                                to use and understand</p>
                            <div class="portfolio-button">
                                <a href="#!">Read More <i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="portfolio-single-box">
                        <div class="portfolio-thumb">
                            <img src="assets/images/portfolio/portfolio-5.jpg" alt=""/>
                        </div>
                        <div class="portfolio-content overlay">
                            <h3>Brewing Guide</h3>
                            <p>Build websites with no coding skills,
                                tools are drag & drop, intuitive, easy
                                to use and understand</p>
                            <div class="portfolio-button">
                                <a href="#!">Read More <i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="portfolio-single-box">
                        <div class="portfolio-thumb">
                            <img src="assets/images/portfolio/portfolio-6.jpg" alt=""/>
                        </div>
                        <div class="portfolio-content overlay">
                            <h3>Brewing Guide</h3>
                            <p>Build websites with no coding skills,
                                tools are drag & drop, intuitive, easy
                                to use and understand</p>
                            <div class="portfolio-button">
                                <a href="#!">Read More <i class="bi bi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Portfoliosection;
