import React from 'react';

const Features = () => {
  return (
    <div>
       <section class="feature-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 wow fadeInDown">
                    <div class="comfy-section-title">
                        {/* <div class="section-icon"><img src="assets/images/resource/section-icon.png" alt=""/></div> */}
                        <div class="section-icon"><img src="assets/images/feature/feature.png" alt="" style={{width:'5%'}}/></div>
                        <h5>Our Features</h5>
                        <h2  style={{fontWeight:'600', fontSize:'32px'}}>What we provide you</h2>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0.3s">
                    <div class="single-feature-box">
                        <div class="feature-icon">
                            <img loading="lazy" src="assets/images/feature/coffee-cup.svg" onload="SVGInject(this)" alt=""/>
                        </div>
                        <div class="feature-title">
                            <h3>Irani Chai</h3>
                        </div>
                        <div class="feature-details cursor-scale">
                            <p>A traditional Persian Irani tea enjoyed for its rich flavor and cultural significance.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay="0.5s">
                    <div class="single-feature-box">
                        <div class="feature-icon">
                            <img loading="lazy" src="assets/images/feature/tea-cup.svg" onload="SVGInject(this)" alt=""/>
                        </div>
                        <div class="feature-title">
                            <h3>Authentic Taste</h3>
                        </div>
                        <div class="feature-details cursor-scale">
                            <p>Renowned for its authentic taste with aromatic spices for a truly satisfying experience.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInRight" data-wow-delay="0.5s">
                    <div class="single-feature-box">
                        <div class="feature-icon">
                            <img loading="lazy" src="assets/images/feature/coffee-mechine.svg" onload="SVGInject(this)" alt=""/>
                        </div>
                        <div class="feature-title">
                            <h3>Flavored Milk</h3>
                        </div>
                        <div class="feature-details cursor-scale">
                            <p>Experience the creamy bliss of flavored milk with every sip, a perfect fusion of goodness and delicious flavor.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInRight" data-wow-delay="0.3s">
                    <div class="single-feature-box">
                        <div class="feature-icon">
                            <img loading="lazy" src="assets/images/feature/coffee-cup2.svg" onload="SVGInject(this)" alt=""/>
                        </div>
                        <div class="feature-title">
                            <h3>Coffee To Go</h3>
                        </div>
                        <div class="feature-details cursor-scale">
                            <p> Indulge in the convenience of our freshly brewed coffee, prepared and packaged for those on the move.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
  );
}

export default Features;
